<template>
    <el-container>
        <div class="header">
            <div class="container" style="">
                <div class="logo">
                    <img src="@/assets/logo.jpeg" alt="分时预约系统">
                </div>
            </div>
        </div>
        <el-main class="main">
            <div class="container flex">
                <div class="solution">
                    <h1>门诊体检分时预约管理系统</h1>
                    <h2>科技创新 受益全民</h2>
                </div>
                <div class="login">
                    <h1>用户登录</h1>
                    <form charset="utf-8" @submit.prevent="doLogin">
                        <div>
                            <label for="id_username">用户名</label>
                            <input type="text" v-model="form.username" autofocus required id="id_username" placeholder="请输入用户名">
                        </div>
                        <div>
                            <label for="id_password">密码</label>
                            <input id="id_password" type="password" v-model="form.password" placeholder="请输入密码" required>
                        </div>
                        <div>
                            <el-button native-type="submit" :loading="loading" class="btn btn-success btn-block btn-large">用户登录</el-button>
                        </div>
                    </form>
                </div>
            </div>
        </el-main>
        <el-footer class="footer">
            <div>
                建议使用谷歌浏览器1280*800以上分辨率浏览 | 客服热线 133-0459-1224
            </div>
            <div>
                Copyright © 2021 上海佐相信息科技有限公司
                <a href="http://www.beian.miit.gov.cn" target="_blank"
                   style="text-decoration: none; color: rgb(102, 102, 102);">沪ICP备19020320号-7</a>
            </div>
            <div>
                当前版本: {{$pkg.version}}
            </div>
        </el-footer>
    </el-container>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                form: {
                    username: '',
                    password: '',
                }
            }
        },
        methods: {
            async doLogin() {
                this.loading = true
                try {
                    const resp = await this.$http.post('/auth/login', this.form)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        console.log(resp.data.data.token)
                        this.$store.commit('set_username', this.form.username)
                        this.$store.commit('set_token', resp.data.data.token)
                        // this.$notify({
                        //     type: 'success',
                        //     message: '欢迎你, ' + this.form.username + '!',
                        //     duration: 3000
                        // })
                        await this.$router.push({path: '/main/'})
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            }
        },
        mounted() {
            let username = localStorage.getItem("username")
            if (username) this.form.username = username
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .header {
        height: 160px !important;
        line-height: 160px !important;
        background-color: #ffffff;

        .logo {
            padding: 25px;

            img {
                max-height: 80px;
                /*width: 100%;*/
            }
        }
    }

    .main {
        background-color: #2081c0;
        min-height: 450px;
        flex: none;
        padding: 30px 0 0 0;

        .flex {
            height: 460px;
            background-image: url(https://cdn.zhack.com/zkui/img/banner2.png);
            background-repeat: no-repeat;
            display: flex;
            algin-items: center;
            just-content: space-between;
        }

        .solution {
            flex: 1;
            min-width: 0;
            text-align: center;
            padding-top: 40px;
            color: #ffffff;
        }

        .solution h1 {
            font-size: 180%;
            padding: 10px;
        }

        .solution h2 {
            font-size: 120%;
            padding: 10px;
            color: #aadcff;
        }

        .login {
            border: 1px solid #8bbad5;
            border-top: 6px solid #4f9fe7;
            border-radius: 3px;
            box-sizing: border-box;
            margin: 30px 30px 50px 0;
            width: 350px;
            background: #ffffff;
        }

        .login h1 {
            padding-top: 25px;
            font-size: 150%;
            text-align: center;
        }

        .login form {
            margin: 20px;
        }

        .login div {
            margin: 20px auto;
        }

        .login label {
            display: block;
            padding-left: 5px;
            font-size: 120%;
        }

        .login input {
            display: block;
            border: none;
            box-sizing: border-box;
            width: 100%;
            padding: 5px;
            background: transparent;
            border-bottom: 1px solid #cccccc;
            outline: 0px;
        }

        .login button {
            margin: 20px auto;
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            color: #ffffff;
            border: none;
            border-radius: 5px;
            background: -webkit-gradient(linear, left top, right bottom, from(#409eff), to(#409eff));
        }

    }

    .footer {
        margin-top: 20px;
        text-align: center;

        div {
            line-height: 50px;
        }

    }

    @media (max-width: 900px) {
        .logo-pku, .solution {
            display: none;
        }
        .main .flex {
            background-color: #2081c0;
            background-image: none;
            width: 100%;
        }
        .main .login {
            margin: 20px auto;
            border: none;
        }
    }
</style>
